<template>
  <div class="pageBox">
    <el-card shadow="never">
      <el-table :data="tableData" border style="width: 100%" v-loading="loading">
        <el-table-column prop="name" label="角色名" width="200" />
        <el-table-column label="角色类型" width="100">
          <template #default="scope">
            <span v-if="scope.row.type === 0">主角色</span>
            <span v-else>子角色</span>
          </template>
        </el-table-column>
        <el-table-column label="角色权限">
          <template #default="scope">
            <el-tag v-for="i in scope.row.menus" :key="i.id" size="small" class="md-5">{{i.title}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="200" align="center">
          <template #default="scope">
            <el-button type="primary" @click="editBtn(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page-pagination">
        <el-pagination
          :current-page="page"
          background
          layout="prev, pager, next, sizes, total"
          :total="total"
          :page-sizes="[10, 50, 100]"
          :page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </el-card>

    <el-dialog v-model="addDialog" :title="item.id ? '编辑角色' : '新增角色'" width="800px">
      <el-form :model="item" label-width="90px">
        <el-form-item label="角色名：">
          <el-input v-model="item.name" style="width: 400px;" disabled></el-input>
        </el-form-item>
        <el-form-item label="角色类型：">
          <el-select v-model="item.type" placeholder="请选择" disabled>
            <el-option label="主角色" :value="0"></el-option>
            <el-option label="子角色" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <div style="display: flex;">
          <el-form-item label="菜单权限：">
            <el-tree ref="tree"
                    :data="menuList"
                    show-checkbox
                    node-key="id"
                    :props="defaultProps"
                    :check-strictly="true"
                    :expand-on-click-node="false"
                    :default-checked-keys="item.menus" />
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addDialog = false">取消</el-button>
          <el-button type="primary" @click="submitBtn">确定</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import { onMounted, reactive, toRefs, nextTick, ref } from 'vue'
import { get, post } from '@/api/request'
import { ElMessage } from 'element-plus'

export default {
  setup() {
    const data = reactive({
      page: 1,
      total: 0,
      tableData: [],
      loading: false,
      item: {},
      menuList: [],
      addDialog: false,
      defaultProps: {
        parent: 'parent_id', // 父级唯一标识
        value: 'id', // 唯一标识
        label: 'title', // 标签显示
        children: 'children', // 子级
      },
    })

    function handleCurrentChange(e) {
      data.page = e
      getRoleList()
    }

    function handleSizeChange(e) {
      data.page = 1
      data.pageSize = e
      getRoleList()
    }

    async function getRoleList() {
      data.loading = true
      await get('/api/role', { page: data.page, pageSize: 10 }).then((res) => {
        data.tableData = res.data
        data.total = res.meta.total
        data.loading = false
      }).catch(() => {
        data.loading = false
      })
    }

    async function editBtn(item) {
      data.item = { ...item }
      let menu = []
      item.menus.forEach((i) => {
        menu.push(i.id)
      })
      data.item.menus = menu
      await nextTick(async () => {
        data.menuList = [...data.menuList]
        data.addDialog = true
      })
    }

    let tree = ref(null)
    function submitBtn() {
      let params = {
        roleId: data.item.id,
        menus: tree.value.getCheckedKeys()
      }
      post('/api/role/storeMenus', params).then(() => {
        ElMessage({
          type: 'success',
          message: '编辑成功'
        })
        data.addDialog = false
        getRoleList()
      })
    }

    function getMenuList() {
      get('/api/menu').then((res) => {
        data.menuList = res.data
      })
    }

    onMounted(() => {
      getRoleList()
      getMenuList()
    })

    return {
      tree,
      ...toRefs(data),
      handleCurrentChange,
      handleSizeChange,
      getMenuList,
      editBtn,
      submitBtn
    }
  }
}
</script>

<style lang="scss" scoped>
  .md-5{
    margin: 3px;
  }
</style>